import { talkka } from "../logging";

/**
 * Determine which datastore errors need to be logged with talkka.error, and
 * which can be logged with talkka.warn (to avoid triggering monitoring rules)
 *
 * @param { Error } error - to log, caught by datastore function 
 * @param { String } message - unique to endpoint, logged as eventDetail (with
 * error.message) in logEvent
 */
export const conditionallyLog = ({ error, message }) => {

  // Almost everything that gets passed in here should be logged as an error, except if we get a "failed to fetch" or "load failed"
  const messageLower = error?.message?.toLowerCase();
  const talkkaFunc = (
    messageLower?.includes("failed to fetch")
    || messageLower?.includes("load failed")
  )
    ? "warn"
    : "error";

  // Use selected logging function to record logEvent
  talkka[talkkaFunc](message + ": " + error?.message);
};
