import React  from "react";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";

import "./loading.css";

/**
 * Component for loading screen
 */
const Loading = () => {

  const { t } = useTranslation();

  return ( 
    <div className="loadingComponent"> 
      <div> { t("components.loading.loading") } </div>
      <ReactLoading className="loadingSpinner" type="spin" color="#D66200" height={"1em"} width={"1em"}/>
    </div>
  );
};

export default Loading;
