// Helpers to support campaigns
import { checkCampaignAndUpdateStudent } from "./datastore";

/**
 * Check if we have the checkCampaigns flag, incidating we should use utm_campaign to look
 * for a campaign benefit for the student   
 * 
 * @param { Object } location - location from useLocation hook
 * @returns { Object } campaign data
 */
export const runCampaignCheck = async ( location ) => {
  const searchParams = new URLSearchParams(location.search);
    
  const checkCampaigns = searchParams.get("checkCampaigns");
  const associatedUtmValue = searchParams.get("utm_campaign");

  // if the checkCampaigns flag is present and we have an associated utm value
  if (checkCampaigns && checkCampaigns === "true" && associatedUtmValue) {
    const campaignData = await checkCampaignAndUpdateStudent( associatedUtmValue );
    return campaignData;
  }

  return false;
};
  
/**
 * Check for the most recently redeemed campaign of a given student
 * 
 * @param { Map } redeemedCampaigns - redeemedCampaigns map from user context
 * @returns { Object } mostRecentUtmValue, mostRecentTime (UTC), mostRecentNewStudentVipExipration (MM/dd/yyyy) representing info for most recently redeemed campaign
 */
export const getMostRecentlyRedeemedCampaign = ( redeemedCampaigns ) => {
  let mostRecentUtmValue = "";
  let mostRecentTime = "";
  let mostRecentNewStudentVipExpiration = "";

  // iterate through user's redeemedCampaigns map
  for ( const [associatedUtmValue, value] of Object.entries(redeemedCampaigns)) {
    if (value.campaignRedeemedAtUTC >= mostRecentTime) {
      mostRecentUtmValue = associatedUtmValue;
      mostRecentTime = value.campaignRedeemedAtUTC;
      mostRecentNewStudentVipExpiration = value.studentNewVipExpiration;
    }
  }
  return { mostRecentUtmValue, mostRecentTime, mostRecentNewStudentVipExpiration };
};

/**
 * Check for the most recently redeemed campaign of a given student and if it's being applied at the current time
 * 
 * @param { Map } redeemedCampaigns - redeemedCampaigns map from user context
 * @returns { String } utm value of most recently redeemed campaign, if one applies to student right now, or false
 */
export const getAssociatedCampaignUtmValue = ( redeemedCampaigns ) => {
  // Find our most recently redeemed campaign's utm value and time redeemed
  const { mostRecentUtmValue, mostRecentNewStudentVipExpiration } = getMostRecentlyRedeemedCampaign(redeemedCampaigns);

  // Check if the student vip expiration date associated with the most recently redeemed campaign is still in effect
  const vipExpDateObj = new Date(mostRecentNewStudentVipExpiration);
  vipExpDateObj.setDate(vipExpDateObj.getDate() + 1);
  vipExpDateObj.setUTCHours(0);
  vipExpDateObj.setUTCMinutes(0);
  vipExpDateObj.setUTCSeconds(0);
  vipExpDateObj.setUTCMilliseconds(0);

  if ((new Date()).toISOString() <= vipExpDateObj.toISOString()) {
    return mostRecentUtmValue;
  }
    
  return false;
};

