import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { getCurrentUser } from "../../context/UserContext";

import Loading from "../Loading";

/**
 * Component used to wrap rendered children of any route that requires
 * logged-in user. Renders the children if the user is logged in, or pushes user
 * to given redirect, if not.
 *
 * @param { React.ReactNode } children - Component(s) to render on given route
 * @param { String } redirectTo - Route to which user should be redirected if
 * not logged in. Is "/login" by default
 * @param { String } redirectAfterAuth - (optional) param to use for redirectAfterSignup 
 * that will be used after user logs in
 */
const RequireAuth = ({
  children,
  redirectTo = "/login",
  redirectAfterAuth = "",
}) => {
  /*
   * HOOKS
   */

  // useLocation hook to get email in query string if it exists
  const location = useLocation();

  // Get user info from context
  const user = getCurrentUser();

  const [ userIsAuthorized, setUserIsAuthorized ] = useState( null );

  useEffect(() => {
    setUserIsAuthorized(
      !!(user?.isLoggedIn || localStorage.getItem("signUpSucceeded")),
    );
  }, [ user ]);

  // If userHasAuth is undefined or null, we haven't yet loaded user info and need to wait
  if ( typeof userIsAuthorized !== "boolean" ) {
    return <Loading />;
  }

  const originalPath = location.pathname.replace("/", "");
  let redirectQuerystring = location.search.replace("?", "&");

  // If we specifiy where to redirect after user logs in, add location as query param to be used after successful login
  if (redirectAfterAuth) {
    redirectQuerystring = "?redirectAfterSignup=" + redirectAfterAuth + redirectQuerystring;
  }
  // If we're not trying to direct to the login or signup endpoints and don't specify a redirect location, use original path attempted
  else if (!["login", "signup"].includes(originalPath) && originalPath !== "") {
    redirectQuerystring = "?redirectAfterSignup=" + originalPath + redirectQuerystring;
  }
  else {
    redirectQuerystring = redirectQuerystring.replace("&", "?");
  }

  // If we have a logged-in user or someone just signed up, return the contents of the route. Otherwise, redirect as indicated
  return userIsAuthorized
    ? children
    : <Navigate to={ redirectTo + redirectQuerystring } />;
};

export default RequireAuth;
