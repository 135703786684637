// Helpers to deal with time!

/**
 * Get local human-readable datetime from lessonScheduledStartUTC ISO string
 * 
 * @param { String } lessonScheduledStartUTC - ISO string with scheduled lesson
 * start time
 * @returns { String } with localized datetime in format "Month day at HH:MM am/pm"
 */
export const getLocalDateTimeFromLessonScheduledStartUTC = (lessonScheduledStartUTC) => {
  // Generate Date object from lesson scheduled start time
  const startDateObject = new Date(lessonScheduledStartUTC);
  
  // Get formatted start time from helper function
  const startTime = getLocalTimeFromLessonScheduledStartUTC(lessonScheduledStartUTC);

  // Get abbreviated month
  const startMonth = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][startDateObject.getMonth()];

  // Get day of month
  const startDateOfMonth = startDateObject.getDate();

  // Format the start date into a complete string!
  const startDate = `${startMonth} ${startDateOfMonth}`;

  // Return the formatted string
  return `${startDate} at ${startTime}`;
};

/**
 * Get local human-readable datetime from lessonScheduledStartUTC ISO string
 * 
 * @param { String } lessonScheduledStartUTC - ISO string with scheduled lesson
 * start time
 * @returns { String } with localized time in format "HH:MM am/pm"
 */
export const getLocalTimeFromLessonScheduledStartUTC = (lessonScheduledStartUTC) => {
  // Generate Date object from lesson scheduled start time
  const startDateObject = new Date(lessonScheduledStartUTC);

  // Get hour of lesson start time
  const startHours = startDateObject.getHours();

  // Convert lesson start hour from 24-hour clock to 12-hour clock
  let startHoursFormatted = startHours > 12 ? startHours - 12 : startHours;

  // Get time of day (am or pm)
  const startTimeOfDay = startHours >= 12 ? "PM" : "AM";

  // set startHoursFormatted to be 12 if startHours = 0
  if (startHoursFormatted === 0) {
    startHoursFormatted = 12;
  }
  
  // Get minutes of lesson start time
  const startMinutes = startDateObject.getMinutes();

  // If single-digit minutes, add leading 0
  const startMinutesFormatted = startMinutes < 10 ? `0${startMinutes}` : startMinutes;

  // Format the start time into a complete string!
  const startTime = `${startHoursFormatted}:${startMinutesFormatted} ${startTimeOfDay}`;

  return startTime;
};

/**
 * Get local human-readable datetime from lessonScheduledStartUTC ISO string
 * 
 * @param { String } lessonScheduledStartUTC - ISO string with scheduled lesson
 * @param { Boolean } includeDayOfWeek [optional] - flag to indicate if day of week should be included, defaults true
 * @param { Boolean } includeYear [optional] - flag to indicate if year should be included, defaults false
 * @param { String } lange - string representing language to render in; defaults to "en"
 * start time
 * @returns { String } with localized datetime in format "ddd mmm dd, HH:MM am/pm" with optional year
 */
export const getLocalDateTimeFromLessonScheduledStartUTCForHome = (
  lessonScheduledStartUTC,
  includeDayOfWeek = true,
  includeYear = false,
  lang = "en",
) => {
  // Generate Date object from lesson scheduled start time
  const startDateObject = new Date(lessonScheduledStartUTC);
  
  // Get formatted start time from helper function
  const startTime = getLocalTimeFromLessonScheduledStartUTC(lessonScheduledStartUTC);

  // arrays to use to map weekday and month indexes to 3-char strings
  const weekdays = [
    { en: "Sun", es: "Do" },
    { en: "Mon", es: "Lu" },
    { en: "Tue", es: "Ma" },
    { en: "Wed", es: "Mi" },
    { en: "Thu", es: "Ju" },
    { en: "Fri", es: "Vi" },
    { en: "Sat", es: "Sa" },
  ];
  const months = [
    { en: "Jan", es: "ene" },
    { en: "Feb", es: "feb" },
    { en: "Mar", es: "mar" },
    { en: "Apr", es: "abr" },
    { en: "May", es: "may" },
    { en: "Jun", es: "jun" },
    { en: "Jul", es: "jul" },
    { en: "Aug", es: "ago" },
    { en: "Sep", es: "sep" },
    { en: "Oct", es: "oct" },
    { en: "Nov", es: "nov" },
    { en: "Dec", es: "dic" },
  ];

  // Get abbreviated month
  const startMonth = months[startDateObject.getMonth()][lang];

  // Get abbreviated day of week
  const startDayOfWeek = weekdays[startDateObject.getDay()][lang];

  // Get day of month
  const startDateOfMonth = startDateObject.getDate();

  // Format the start date into a complete string!
  let startDate = `${startDateOfMonth} ${startMonth}`;

  // Conditionally include year
  if (includeYear) {
    startDate += " " + startDateObject.getFullYear();
  }

  // Conditionally add day of week
  if (includeDayOfWeek) {
    startDate = `${startDayOfWeek} ` + startDate;
  }

  // Return the formatted string
  return `${startDate}  -  ${startTime}`;
};

/**
 * Get a JS Date object given a string representing a VIP expiration date in the format MM/dd/yyyy
 * Note: this will assume the time it should end is 10:00AM EDT (14:00 UTC)
 * 
 * @param { String } oldFormatVIPExpirationDate - ISO string with scheduled lesson
 * start time
 * @returns { Date } JS Date object
 */
export const getDateObjFromOldFormatVIPExpirationDate = (
  oldFormatVIPExpirationDate,
) => {
  const [month, day, year] = oldFormatVIPExpirationDate.split("/");
  const monthIndex = month - 1;
  const vipExpirationDateObj = new Date();

  // Set UTC date to be YYYY-mm-DD
  vipExpirationDateObj.setUTCFullYear(year);
  vipExpirationDateObj.setUTCMonth(monthIndex);
  vipExpirationDateObj.setUTCDate(day);
  // set date's hour to align with when we run the endVips Cloud Function (currently at 10:00AM EDT / 14:00 UTC)
  vipExpirationDateObj.setUTCHours(14);
  vipExpirationDateObj.setUTCMinutes(0);
  vipExpirationDateObj.setUTCSeconds(0);
  vipExpirationDateObj.setUTCMilliseconds(0);

  // Return date object
  return vipExpirationDateObj;
};
