import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

const activebuildconfig = require("./configs/activebuildconfig.json");

// Initialize firebase app
export const firebaseApp = initializeApp({
  apiKey: activebuildconfig.FIREBASE_SETTING_API_KEY,
  appId: activebuildconfig.FIREBASE_SETTING_APP_ID,
  authDomain: activebuildconfig.FIREBASE_SETTING_AUTH_DOMAIN,
  databaseURL: activebuildconfig.FIREBASE_SETTING_RTD_URL,
  measurementId: activebuildconfig.FIREBASE_SETTING_MEASUREMENT_ID,
  messagingSenderId: activebuildconfig.FIREBASE_SETTING_MESSAGING_SENDER_ID,
  projectId: activebuildconfig.FIREBASE_SETTING_PROJECT_ID,
  storageBucket: activebuildconfig.FIREBASE_SETTING_STORAGE_BUCKET,
});

// Get auth element for use downstream
export const firebaseAuth = getAuth( firebaseApp );

// Get analytics for use downstream
export const firebaseAnalytics = getAnalytics( firebaseApp );

// Get Real Time Database for use downstream
export const firebaseRealTimeDatabase = getDatabase( firebaseApp );

// Get Play Real Time Database for use downstream
export const firebasePlayRealTimeDatabase = getDatabase( firebaseApp, activebuildconfig.FIREBASE_SETTING_PLAY_RTD_URL );
