import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Daily from "@daily-co/daily-js";

import MegaTalkka from "../../assets/talkka_megaphone.png";

import CookieDetection from "../CookieDetection";

import "./browserblocker.scss";

/**
 * Component for blocking students who have an unsupported browser type from using the Speak App
 */
const BrowserBlocker = () => {

  const { t } = useTranslation();
  const content = t("components.browserBlocker", { returnObjects: true });

  let isBeepboopSupportedBrowser = true;

  const userAgent = window.navigator.userAgent;

  // block Internet Explorer 10 & below (MSIE) and Internet Explorer 11 & up (Trident)
  // block Samsung & Mi Browser (EdgW)
  // block Firefox (at least on Firefox for Mac, when focus is lost, it doesn't respond to microphone deactivation/reactivation requests & it aggressively kills sockets & disconnects from WebRTC servers, so taking a sledgehammer)
  // block Miui Browser
  // block Android Webview
  if (userAgent.indexOf("MSIE") > 0 
    || userAgent.indexOf("Trident") > 0
    || userAgent.indexOf("SamsungBrowser") > 0 
    || userAgent.indexOf("EdgW") > 0
    || userAgent.indexOf("Firefox") > 0
    || userAgent.indexOf("MiuiBrowser") > 0
    || userAgent.indexOf("; wv") > 0
  ) {
    isBeepboopSupportedBrowser = false;
  }
  
  // isBrowserSupported if browser is NOT supported by Daily
  const isBrowserSupported = Daily.supportedBrowser().supported && isBeepboopSupportedBrowser;
  
  return ( 
    <>
      <CookieDetection/>
      <Modal 
        show={!isBrowserSupported} 
        onHide={()=>{}}
        animation={false} 
        centered={true} 
        dialogClassName="browserBlockerModal" 
        size="xl">
        <Modal.Header>
          <div className="bbHeader">
            <img className="talkkaMegaphone" src={MegaTalkka} />
            { content.heading }
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="supportInfo">
            { content.notCompatible }
            {" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.google.com/chrome/" className="downloadRedirectButton"
            >
              { content.recommendChrome }
            </a>
            { content.getHelp }
            {" "}
            <a
              className="emailLink"
              target="_blank"
              href={`mailto:amigos@beepboop.us?subject=${ content.emailSubject }&body=${ content.emailBody }: ${ userAgent }`}
            >
              amigos@beepboop.us
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BrowserBlocker;
