import React, {
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

const NavbarQuoteArea = () => {

  const location = useLocation();

  // Static array of reviews to show below Buy Pro button
  const reviews = [
    "\"In 6 months, I have dramatically improved my ability to speak. I never thought I would be fluent in Spanish but now I feel like I could handle any conversation with any Spanish speaker.\"",
    "\"Beepboop is now an indispensable part of my Spanish learning. I do a daily drill working on pronunciation and comprehension. The drill instructors are fun and enthusiastic teachers...you will never be bored! You have to check it out!\"",
    "\"Beepboop is a fun, quick way to have daily practice with Spanish over a variety of topics...I have done as many as five lessons within a week and they never get old. I have learned so many vocabulary words and phrases...a student can easily apply the material to a real-world situation.\"",
    "\"Very effective way of learning a language. Vocabulary in each module is learned and reinforced through short dialogues. Live instructors give pronunciation corrections and keep a lively pace and ensure a positive and fun learning atmosphere.\"",
  ];

  // Remember the randomly-selected review to display
  const [ selectedReview, setSelectedReview ] = useState( null );

  // When location changes, pick a new review for the menu
  useEffect(() => {
    // Grab a random review from the static array, and set it in state
    setSelectedReview(
      reviews[ Math.floor( Math.random() * reviews.length )],
    );
  }, [ location ]);

  return (
    <div className="bottomQuoteContainer">
      { selectedReview }
      <br/>
      <div className="bottomReviewLink">
        <a 
          target="_blank" 
          rel="noreferrer noopener" 
          href="https://www.facebook.com/beepboop.us/reviews/"
        >
          Read more reviews from Beepboop Pros
        </a>
      </div>
    </div>
  );
};

export default NavbarQuoteArea;
