import React from "react";
import "./hamburger.scss";

/**
 * Renders fancy hamburger button for menu in mobile view (700px breakpoint), from Teach App by Lucas
 *
 * @param {*} props destructured into: open, state var from nav.js that indicates whether the mobile nav is open or closed. toggleMenu, setter to toggle open
 * @returns markup for hamburger
 */
const Hamburger = ({ open, toggleMenu, displayHamburger = true }) => (
  displayHamburger ?
    <button type="button" onClick={toggleMenu} className="nav__burger">
      <div className={`${open ? "open" : ""}`} />
      <div className={`${open ? "open" : ""}`} />
      <div className={`${open ? "open" : ""}`} />
    </button>
    : <></>
);

export default Hamburger;
