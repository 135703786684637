import React, { useEffect, useState } from "react";
import "./versionCheck.scss";
import { Modal } from "react-bootstrap";
import MegaTalkka from "../../assets/talkka_megaphone.png";

import activebuildconfig from "../../configs/activebuildconfig.json";
const { APP_POINTER } = activebuildconfig;

import { getVersionCheck } from "../../services/datastore";

/**
 * Component for alerting students that they aren't using the latest version of the Speak App * 
 * @param { Object } children - contents of the page
 */
const VersionCheck = ({ children }) => {
  const [ checkingVersion, setCheckingVersion ] = useState(true);
  const [ showVersionCheckModal, setShowVersionCheckModal ] = useState(false);
  const [ versionData, setVersionData ] = useState([]);
  const [ showProxyCheckModal, setShowProxyCheckModal ] = useState(false);
  const [ requesterIP, setrequesterIP ] = useState("");

  const userAgent = window.navigator.userAgent;

  /*
   * USE EFFECT
   */
  // fetch version check from server
  async function asyncFetchVersionCheck() {
    // send request to backend to get version check info
    const versionCheckData = await getVersionCheck();

    setVersionData(versionCheckData);
  
    // set student object in studentData state
    if (versionCheckData?.forceRefresh) {
      setShowVersionCheckModal(true);
    }

    setrequesterIP(versionCheckData?.requesterIP);

    // determine if student is using a proxied IP (i.e. VPN, browser mini proxy)
    // example: 105.111.183.151,82.146.208.178
    if (versionCheckData?.requesterIP?.includes(",")) {
      setShowProxyCheckModal(true);
    }
  }

  // On component load, check to see if Student is on current version
  useEffect(() => {
    // Only check version if not on local dev. This ensures that feature branches cut from old versions can be run locally for development & acceptance testing
    if ( APP_POINTER !== "DEV" ) {
      // initial fetchVersionCheck call
      async function fetchVersionCheck() {
        try {
          await Promise.all([
            asyncFetchVersionCheck(),
          ]).catch(err => {
            console.log(err);
          });

          setCheckingVersion(false);
        }
        catch {
          console.log("Error fetching version check");
        }
      }
      fetchVersionCheck();
    }
    // If on dev, we just need to set checkingVersion to false
    else {
      setCheckingVersion(false);
    }
  }, []);

  // On button click, reload current page
  const bustCacheAndReload = () => {
    
    // attempt to also bust service worker cache (to force re-fetch by desktop Chrome)
    /*if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function(names) {
        for (const name of names) caches.delete(name);
      });
    }*/
    
    window.location.reload();
  };

  // only allow to proceed after version has been checked
  if (checkingVersion) {
    // if version hasn't been checked yet, don't return children
    return null;
  }
  else if (showProxyCheckModal) {
    // if student is attempting to log in using a proxied IP, display "disable VPN" modal
    return ( 
      <Modal 
        show={showProxyCheckModal}
        onHide={()=>{}} 
        animation={false} 
        centered={true} 
        dialogClassName="versionCheckModal" 
        size="xl">
        <Modal.Header>
          <div className="vcHeader">
            <img className="talkkaMegaphone" src={MegaTalkka} />
                VPN or IP Proxy Detected
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="supportInfo">
              Unfortunately, Beepboop classes are not joinable if you're using a VPN or other proxy.
              <br/><br/>
              If you still see this message after disabling your VPN and refreshing this page, 
              email us at <a className="emailLink" target="_blank" href={"mailto:amigos@beepboop.us?subject=Student Needs Help: Proxy IP Detected&body=Technical Details: " + requesterIP + " | " + userAgent}>amigos@beepboop.us</a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  else if (showVersionCheckModal) {
    // if version is behind current server Speak App version, display "must get new version" modal
    return ( 
      <Modal 
        show={showVersionCheckModal}
        onHide={()=>{}} 
        animation={false} 
        centered={true} 
        dialogClassName="versionCheckModal" 
        size="xl">
        <Modal.Header>
          <div className="vcHeader">
            <img className="talkkaMegaphone" src={MegaTalkka} />
                New Version
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="supportInfo">
              You are on {versionData.clientVersion} of the Speak App
              <br/><br/>
              There is a new version available ({versionData.serverVersion})
              <div
                onClick={bustCacheAndReload} className="versionOutdatedRefreshButton"
              >
                Tap to Get New Version
              </div>
              Or try one of these keyboard combinations:
              <br/>
              Ctrl-Shift-R (Windows)
              <br/>
              Command-Shift-R (Mac)
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  else {
    // proceed with mounting additional children because version has been checked and is high enough
    return (<>{children}</>);
  }
};

export default VersionCheck;
