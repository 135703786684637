import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import JSON translation files - automatically parsed into JS objects
import englishTranslation from "./translations/en.json";
import spanishTranslation from "./translations/es.json";

const resources = {
  en: englishTranslation,
  es: spanishTranslation,
};

// Check if we've already saved language info in localStorage
const student = localStorage.getItem("currentStudent");
const language = student ? (JSON.parse(student))?.languageFluent : "en";

// Initialize translation
i18n
  // Set up React package
  .use( initReactI18next )
  // Connect to resources and add settings/defaults
  .init({
    resources,
    // react-i18next docs say React is already xss-safe, so don't need interpolation
    interpolation: {
      escapeValue: false,
    },
    // Use language from localStorage, with English as fallback
    lng: language ? language : "en",
    fallbackLng: "en",
  });

export default i18n;
