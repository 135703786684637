import { getIdToken } from "firebase/auth";

import version from "../../version/version.json";

import activebuildconfig from "../../configs/activebuildconfig.json";
const APIRootUrl = activebuildconfig.API_ROOT_URL;

import { firebaseAuth } from "../../firebase";

import { talkka } from "../logging";

import { conditionallyLog } from "./conditionallyLog";

/**
 * Utility to hit our API in a standardized way
 *
 * @param { String } endpoint - last segment of API endpoint hit (everything
 * that comes after /speak/)
 * @param { String } verb [optional] - http verb to use for request (PUT or
 * POST for current endpoints)
 * @param { Object } body [optional] - object to send as request body
 * @param { Boolean } retryOnFailure [optional] - If passed as true, will retry
 * the fetch once in the event of an error (front-end or back-end)
 * TODO: retryOnFailure has not yet been implemented -- be sure to test this flag thoroughly when it's first implemented in a datastore function!
 * @returns full result object from Cloud Functions
 */
export const asyncFetchResult = async ({
  endpoint = "",
  verb = "PUT",
  body = {},
  retryOnFailure = false,
}) => {
  try {
    // If we didn't get an endpoint to hit, we can't do anything!
    if ( !endpoint ) {
      throw new Error("Must supply endpoint to asyncFetchResult");
    }

    // Grab token if we have a logged in user, otherwise is null
    const token = firebaseAuth.currentUser
      ? await getIdToken( firebaseAuth.currentUser )
      : null;

    // Dispatch request and get response
    const response = await fetch(`${ APIRootUrl }/speak/${ endpoint }`, {
      method: verb,
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        version: version.version,
        ...body,
      }),
    });

    // Confirm that request succeeded before trying to parse it
    if ( !response.ok ) {
      const responseText = await response.text();
      throw new Error("status: " + response.status + ": " + responseText);
    }
    
    // Extract data from response
    const result = await response.json();

    // If the back-end call failed and we have a retry flag, retry the call!
    if (
      !result.success
      && retryOnFailure
    ) {
      return await retryAsyncFetchResult({
        endpoint,
        verb,
        body,
      });
    }

    // Add status code to result
    result.status = response.status;

    // Return result so datastore controller can interpret it
    return result;
  }
  catch ( error ) {

    // If retry flag has been passed, retry once!
    if ( retryOnFailure ) {
      return await retryAsyncFetchResult({
        endpoint,
        verb,
        body,
        error,
      });
    }
    
    // To avoid an infinite loop, don't talkka.log a failure in saveLogEvent (since talkka.error calls saveLogEvent, which calls asyncFetchResult)
    if ( !endpoint.includes("save_log_event") ) {
      // Custom logger that prevents connectivity errors from polluting logEvents
      conditionallyLog({
        error,
        message: `Error in ${ verb } call to ${ endpoint }`,
      });
    }
    else {
      // Still log something to the console in DEV environments so that engineers can see that something went wrong with a call to save_log_event -- talkka.log doesn't try to hit the back end
      talkka.log(`Error in ${ verb } call to ${ endpoint }: ${ error }`);
    }

    // Return response-shaped object indicating failure
    return {
      success: false,
      // custom status indicates unknown error from this function - 900 puts us outside of standard http code response range, and 22 stands for BB = beepboop
      status: 922,
    };
  }
};

/**
 * Retry a failed call once
 *
 * @param { String } endpoint - last segment of API endpoint hit (everything
 * that comes after /teach/)
 * @param { String } verb [optional] - http verb to use for request (PUT or
 * POST for current endpoints)
 * @param { Object } body [optional] - object to send as request body
 * @param { Object } error [optional] - error (if any) that led to retry
 * @returns
 */
const retryAsyncFetchResult = async ({
  endpoint,
  verb,
  body,
  error,
}) => {

  // Log that a retry happened
  talkka.info(`Retrying ${verb} request to ${endpoint}${
    error
      ? ` due to front-end failure with message: ${error.message}`
      : ""
  }`);

  // Re-call asyncFetchResult with the same info, but without retry flag, this time
  return await asyncFetchResult({
    endpoint,
    verb,
    body,
    // Could omit retryOnFailure since default is false, but it's here as a sanity check
    retryOnFailure: false,
  });
};
