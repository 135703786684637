import { useEffect } from "react";
import {
  useNavigate,
  useLocation,
} from "react-router-dom";

import Loading from "../Loading";

/**
 * All requests to habla.beepboop.us are redirected here -- parse the URL and
 * redirect appropriately!
 */
const HablaRedirect = () => {

  /*
   * HOOKS
   */

  const location = useLocation();

  const navigate = useNavigate();

  /*
   * USE EFFECT
   */

  // On load, use location and user info to decide what route to render
  useEffect(() => {
    
    // Get path and search from location
    const path = location.pathname.slice(6);
    const { search } = location;

    // A path of "/" isn't really a path, anyway
    const hasPath = path && path !== "/";

    // Case 1: We have both a path and a search string
    if ( hasPath && search ) {
      navigate(`${path}${search}&lt=en`, { replace: true });
    }

    // Case 2: We have query string info, but no path specified
    else if ( !hasPath && search ) {
      // Pass query string through to login page, with lt=en added
      navigate(`/login${search}&lt=en`, { replace: true });
    }

    // Case 3: We have a path, but no query string
    else if ( hasPath && !search ) {
      navigate(`${path}?lt=en`, { replace: true });
    }

    // Case 4: (Fallback) No path or search string provided. Go to login!
    else {
      // If user is logged in, the login path will redirect to book page
      navigate("/login?lt=en", { replace: true });
    }

  }, []);

  /*
   * RENDER
   */

  return <Loading />;
};

export default HablaRedirect;
