import {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { onAuthStateChanged } from "firebase/auth";

import { firebaseAuth } from "../../firebase";

import { inProd } from "../../App";

import Loading from "../../components/Loading";

import { getOneStudentPartial, getStudentPrivileges } from "../../services/datastore";
import { i18n } from "../../services/internationalization";
export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {

  const [ loading, setLoading ] = useState( true );

  const [ currentUser, setCurrentUser ] = useState( null );

  // These state variables combine to ensure that we have info for the logged-in user before rendering the page
  const [ userIsLoggedIn, setUserIsLoggedIn ] = useState( false );
  const [ userInfoIsLoaded, setUserInfoIsLoaded ] = useState( false );

  // On load, set auth state listener that keeps currentUser up-to-date on user login
  useEffect(() => {

    const unsubscribeFromAuth = onAuthStateChanged(
      firebaseAuth,
      async ( user ) => {

        setLoading(true);

        // If we have a user, set info in state to pass through context
        if ( user ) {

          // Activate flag for logged-in user
          setUserIsLoggedIn( true );

          // Get user info from Firebase
          const userInfo = await getOneStudentPartial([
            "email",
            "first_name",
            "languageFluent",
            "languageTarget",
            "last_name",
            "level",
            "organizationId",
            "phone_number",
            "redeemedCampaigns",
            "streak_current",
            "streak_longest",
            "studentId",
            "student_type",
            "VIP_expiration",
            "VIP_registration",
          ]);

          // If landed on this page in production, record user id to be used in Google Analytics
          if ( inProd ) {
            window.dataLayer = window.dataLayer || [];
            function gtag(){
              dataLayer.push(arguments);
            }
            // Measurement ID for Picker App in Google Analytics
            gtag("config", "G-X2RX8GE3G8", {
              "user_id": userInfo?.studentId ? userInfo.studentId : "no_user_id",
            });
          }

          // If we don't have languageTarget, set reasonable defaults for languageTarget and languageFluent
          if ( !userInfo.languageTarget ) {
            userInfo.languageTarget = "es";
            userInfo.languageFluent = "en";
          }
          // Student file could be missing languageFluent, as well
          else if ( !userInfo.languageFluent ) {
            userInfo.languageFluent = userInfo.languageTarget === "en"
              ? "es"
              : "en";
          }

          // Check for organization info
          const organizationId = userInfo.organizationId;
          let privilegeInfo = null;

          if (organizationId) {
            privilegeInfo = await getStudentPrivileges(organizationId);
          }
          
          // Since state-setting is async, we can't guarantee that this info will be available in state before the loading flag is unset below. That's why we have userIsLoggedIn and userInfoIsLoaded
          setCurrentUser({
            ...userInfo,
            isLoggedIn: true,
            studentPrivileges: privilegeInfo,
          });
        }
        // If we don't have user info, that means we logged out!
        else {

          // Set flag for no logged-in user
          setUserIsLoggedIn( false );

          // Clear local state - also removes from localStorage via useEffect below
          setCurrentUser(null);
        }

        setLoading( false );
      });

    return unsubscribeFromAuth;

  }, []);

  // When current user changes, save in local storage. Allows info to persist through browser refresh
  useEffect(() => {

    // If we have info, set flag and save the info it in localStorage
    if ( currentUser ) {
      setUserInfoIsLoaded( true );

      localStorage.setItem(
        "currentUser",
        JSON.stringify(currentUser),
      );
      // Make sure we're rendering the language that the user wants to see
      i18n.changeLanguage( currentUser.languageFluent );
      // Safety check - since we instantiate loading to true, make sure it's false any time we have user info
      setLoading( false );
    }
    // If we don't have info, remove the key from localStorage
    else {
      setUserInfoIsLoaded( false );
      localStorage.removeItem( "currentUser" );
    }

  }, [ currentUser ]);

  const updateCurrentUser = ( update ) => {
    setCurrentUser( prev => ({
      ...prev,
      ...update,
    }));
  };

  // If we're waiting for info to be fetched, or for currentUser to update in state, render the loading spinner
  return ( loading || (userIsLoggedIn && !userInfoIsLoaded) )
    ? (
      <Loading />
    )
    : (
      <UserContext.Provider
        value={{
          currentUser,
          updateCurrentUser,
        }}
      >
        { children }
      </UserContext.Provider>
    );
};

// Helper function to get current user
export const getCurrentUser = () => {
  // Grab context to use
  const context = useContext( UserContext );

  // Return context value if we have it, otherwise indicate that we have no user
  return (
    context?.currentUser
      ? context.currentUser
      : false
  );
};
