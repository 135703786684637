import { Suspense } from "react";

import Loading from "../Loading";

// Implement suspense to facilitate lazy loading and code-splitting!
const LazyLoad = ({ children }) => (
  <Suspense fallback={<Loading />}>
    { children }
  </Suspense>
);

export default LazyLoad;
