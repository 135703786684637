import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Import i18n to make internationalization work across entire app
// Double internationalization here to get to where i18n is the default export -- it's named in index.js to make importing within the app use the same format as other imports from services (named imports)
import "./services/internationalization/internationalization";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// test comments
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
