import React from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";

/**
 * Component which returns props.children rendered with Beepboop markdown
 *
 * @prop { React.Component } children to wrap in markdown parser
 * @prop { boolean } disableRehypePlugins (optional) whether to enable rehypePlugins - default is false. If true, then the markdown will be rendered without rehypePlugins
 * @prop { boolean } unwrap - pass to eliminate the <p> that is placed around the returned markdown by default
 */
export const RenderMarkdown = ({
  children,
  disableRehypePlugins = false,
  unwrap = false,
}) => {

  const elementArgs = {
    children,
    ...!disableRehypePlugins && { rehypePlugins: [ rehypeRaw ] },
    ...unwrap && { components: { p: React.Fragment } },
  };

  return <Markdown { ...elementArgs } />;
};
