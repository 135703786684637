import React from "react";
import { useLocation } from "react-router-dom";

import quokka from "../../assets/quokka.png";
import BackgroundLeaves from "../../assets/BackgroundLeaves.svg";

import MobileNavbar from "../MobileNavbar";

import "./errormessage.css";

/**
 * Component for error message display
 */
const ErrorMessage = () => {

  const location = useLocation();

  // previous page to be used in mobile header, defaults to home if no previous page
  const previousPage = location?.state?.from ? location.state.from : "home";

  return (
    <MobileNavbar
      pageName=""
      returnMessage={previousPage}
      returnPathname={"/" + previousPage}
      returnFrom={previousPage}
      displayGoVIPButton={false}
      displayAnnouncementBar={false}
      hideCampaignsBar={ true }
    >
      <div className="errorBackgroundContainer">
        <img src={BackgroundLeaves}/>
      </div>
      <div className={"errorContainer centerMessage"}>
        <div className="errorHeader">
          <img
            className="errorQuokka"
            src={quokka}
            title="Quokka"
            alt="Quokka"
          />
          <div className="errorTitle">
            Uh Oh - 404
          </div>
        </div>
        <div className="errorMessageMain">
          "404" is like a Beepboop for your web browser.
          <br/><br/>
          Try using the Menu (in the upper right) to find what you're looking for.
        </div>
      </div>
    </MobileNavbar>
  );
};

export default ErrorMessage;
