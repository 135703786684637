import React from "react";

import config from "../../configs/activebuildconfig.json";
const { KNOWLEDGE_BASE_URL_MAP } = config;

import BackgroundLeaves from "../../assets/BackgroundLeaves.svg";
import quokka from "../../assets/quokka.png";

import "./errorFallback.css";

/**
 * Component that is rendered when an unhandled error bubbles up to our
 * ErrorBoundary (previously would cause a WSOD)
 * 
 * @param { CallableFunction } resetErrorBoundary - Function that attempts to clear error state and restart application. Passed to ErrorBoundary as onReset in app.js
 * @returns 
 */
const ErrorFallback = ({ resetErrorBoundary }) => {

  // Show link to home page only if we're not already on it!
  const showHomeLink = window?.location?.pathname !== "/home";

  // Since we can't guarantee that this component will have access to context when rendered, see if we can get user info from localStorage
  const userInfoRaw = localStorage.getItem("currentUser");

  // Get languageFluent from user info, or default to English
  const { languageFluent } = userInfoRaw
    ? JSON.parse(userInfoRaw)
    : { languageFluent: "en" };
  
  return (
    <div className="errorFallback">
      <div className="errorBackgroundContainer">
        <img src={ BackgroundLeaves }/>
      </div>
      {// If we're not at the home page, show a link back to it
        showHomeLink
        && (
          <div className="errorFallback__homeLink">
            <a href="/">&lt; Go Home</a>
          </div>
        )
      }
      <div className={"errorContainer centerMessage fallbackMessage"}>
        <div className="errorHeader">
          <img
            className="errorQuokka"
            src={ quokka }
            title="Quokka"
            alt="Quokka"
          />
          <div className="errorTitle">
            Uh Oh!
          </div>
        </div>
        <div className="errorMessageMain">
          <b>Something went wrong!</b>
          <br />
          You can try to resolve the error by clicking the "Try Again" button below. If you continue to have problems, check out our <a href={ KNOWLEDGE_BASE_URL_MAP[languageFluent] } target="_blank">Knowledge Base</a> for some useful tips.
          <br/><br/>
          <button
            className="errorFallback__button"
            onClick={ resetErrorBoundary }
          >
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
