import React from "react";
import "./cookiedetection.scss";
import { Modal } from "react-bootstrap";
import MegaTalkka from "../../assets/talkka_megaphone.png";

/**
 * Component for alerting students who have cookies disabled
 */
const CookieDetection = () => {

  const areCookiesEnabled = navigator.cookieEnabled;
  
  const userAgent = window.navigator.userAgent;
  
  return ( 
    <Modal 
      show={!areCookiesEnabled} 
      onHide={()=>{}}
      animation={false} 
      centered={true} 
      dialogClassName="cookieDetectionModal" 
      size="xl">
      <Modal.Header>
        <div className="bbHeader">
          <img className="talkkaMegaphone" src={MegaTalkka} />
              Cookies Disabled
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>      
          <div className="supportInfo">To use the Beepboop Speak App, you must enable cookies
            <a target="_blank" rel="noreferrer noopener" href="https://www.whatismybrowser.com/guides/how-to-enable-cookies/" className="downloadRedirectButton" >
              Click to Learn How</a>
              Need help? Email us at <a className="emailLink" target="_blank" href={"mailto:amigos@beepboop.us?subject=Student Needs Help: Cookies Disabled&body=Technical Details for Browser: " + userAgent}>amigos@beepboop.us</a>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CookieDetection;
